/*
-----------------------------------------------------------------------
  Brand Logo CSS
-----------------------------------------------------------------------
*/

.brand-logo-slider {
    align-items: center;
}

.brand-logo-area {
    padding: 100px 0 100px;
    @media #{$md-device} {
        padding: 80px 0 80px;
    }
    @media #{$sm-device} {
        padding: 60px 0 60px;
    }

    .title {
        margin-bottom: 40px;
        @media #{$lg-device} {
            margin-bottom: 15px;
        }
    }

    p {
        font-size: 18px;
        letter-spacing: 0;
        margin-bottom: 130px;
        @media #{$lg-device} {
            font-size: 16px;
            margin-bottom: 65px;
        }
        @media #{$xxs-device} {
            font-size: 13px;
        }
    }
}

.brand-logo-item {
    img {
        opacity: 0.5;
        @include transition(0.3s);
    }

    &:hover {
        img {
            opacity: 1;
        }
    }
}

.brand-logo-slider-container {
    .brand-logo-slider {
        .brand-logo-item {
        }
    }
}
