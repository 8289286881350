//== Typography
//
//## Font and Color for body text, headings, and more.
$font-current-theme1: "DM Sans", sans-serif;
$font-current-theme2: "Libre Baskerville", serif;
$body-font: "Poppins", sans-serif;
$heading-font: $font-current-theme1;
$secondary-font: $font-current-theme2;

$font-size-base: 14px;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

$body-font-weight: 400;
$heading-font-weight: 400;

// body line height
$body-line-height: 1.7142857143;

// body color
$body-color: #666666;

// heading color
$heading-color: #000;

// theme color
$theme-color: #996600;
$theme-color2: #303744;
$theme-color3: #669900;
$theme-color4: #f53400;
$theme-color5: #568701;
$theme-color6: #da4426;
$theme-color7: #cc0000;
$theme-color8: #cc9966;
$theme-color9: #3399cc;
$theme-color10: #0099cc;
$theme-color11: #ff1d5d;

// common color
$black: #000;
$black-111: #111;
$black-222: #222;
$black-333: #333;
$black-444: #444;
$black-555: #555;
$black-666: #666;
$black-777: #777;
$black-888: #888;
$black-999: #999999;
$black-45: #454545;
$black-0e: #0e0e0e;

$white: #fff;
$white-f1: #f1f1f1;
$white-f2: #f2f2f2;
$white-f3: #f3f3f3;
$white-f4: #f4f4f4;
$white-f5: #f5f5f5;
$white-f6: #f6f6f6;
$white-f7: #f7f7f7;
$white-f8: #f8f8f8;
$white-f9: #f9f9f9;
$white-fa: #fafafa;
$white-fb: #fbfbfb;
$white-fc: #fcfcfc;
$white-fd: #fdfdfd;
$white-fe: #fefefe;
$gray-light: #ddd;
$gray-lighter: #eee;
$gray-ccc: #ccc;

//== Responsive Media Query Variables
//
$xlmax-device: "only screen and (min-width: 1200px) and (max-width: 1599.98px)";
$xls-device: "only screen and (min-width: 1400px)";
$xl-device: "only screen and (min-width: 1200px)";
$lg-up-device: "only screen and (min-width: 992px)";
$xlm-device: "only screen and (max-width: 1549px)";
$xlsm-device: "only screen and (max-width: 1400px)";
$xxl-device: "only screen and (max-width: 1399px)";
$lg-device: "only screen and (max-width: 1199px)";
$md-device: "only screen and (max-width: 991px)";
$sm-device: "only screen and (max-width: 767px)";
$xs-device: "only screen and (max-width: 575px)";
$xxs-device: "only screen and (max-width: 479.98px)";
